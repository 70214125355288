<template>
  <AView
    :value="value"
    :active="analyzeCategory.id === activeAnalyzeCategoryId"
    :name="analyzeCategory.name"
    ref="analyzeCategory-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @action="(action) => action.handler({ from: value, analyzeCategory })"
  >
    <template v-slot:content="{}">
      <AnalyzeCategoryDetails
        v-model="analyzeCategory"
        :goodWords="goodWords"
        :badWords="badWords"
        :permissions="value.permissions"
        @action="(action) => action.handler({ from: value, analyzeCategory })"
        @onGoodWordsPaginationChange="onGoodWordsPaginationChange"
        @onBadWordsPaginationChange="onBadWordsPaginationChange"
        @onWordCreate="onWordCreate"
        @onWordRemove="onWordRemove"
        @onWordUpdate="onWordUpdate"
        @onChange="onChange"
      ></AnalyzeCategoryDetails>
    </template>
  </AView>
</template>
    
    
    <script>
import { mapGetters, mapState } from "vuex";
import AnalyzeCategoryDetails from "../../../../../../components/wad/organisms/leads/analyzeCategories/AnalyzeCategoryDetails.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    AnalyzeCategoryDetails,
  },
  data() {
    return {
      analyzeCategory: {},
      goodWords: {
        items: [],
        pagination: {
          pageSize: 10,
        },
      },
      badWords: {
        items: [],
        pagination: {
          pageSize: 10,
        },
      },
    };
  },
  computed: {
    ...mapState("AnalyzeCategoryStore", [
      "activeAnalyzeCategoryId",
      "displayedAnalyzeCategories",
    ]),
    ...mapGetters("AnalyzeCategoryStore", ["analyzeCategoryById"]),
  },
  async created() {
    this.$store.dispatch("AnalyzeCategoryStore/GetAnalyzeCategory", {
      id: this.value.relation.params.analyzeCategoryId,
    });

    this.analyzeCategory = this.analyzeCategoryById(
      this.value.relation.params.analyzeCategoryId
    );

    this.badWords = await this.$store.dispatch(
      "AnalyzeCategoryStore/GetAnalyzeCategoryBadWords",
      {
        analyzeCategoryId: this.value.relation.params.analyzeCategoryId,
        pagination: this.badWords.pagination,
      }
    );

    this.goodWords = await this.$store.dispatch(
      "AnalyzeCategoryStore/GetAnalyzeCategoryGoodWords",
      {
        analyzeCategoryId: this.value.relation.params.analyzeCategoryId,
        pagination: this.goodWords.pagination,
      }
    );
  },
  methods: {
    onHover() {
      this.$store.commit(
        "AnalyzeCategoryStore/setActiveId",
        this.value.relation.params.analyzeCategoryId
      );
    },

    onChange() {
      console.log("Changes: ");

      const toUpdate = {
        ...this.analyzeCategory,
        config: JSON.stringify(this.analyzeCategory.config),
      };

      this.$store.dispatch("AnalyzeCategoryStore/UpdateAnalyzeCategory", {
        analyzeCategory: toUpdate,
      });
    },
    async onWordCreate(type, word) {
      await this.$store.dispatch("AnalyzeCategoryStore/CreateWord", {
        type,
        word,
        analyzeCategoryId: this.value.relation.params.analyzeCategoryId,
      });
      if (type === "bad") this.onBadWordsPaginationChange();
      else this.onGoodWordsPaginationChange();
    },
    async onWordRemove(type, word) {
      await this.$store.dispatch("AnalyzeCategoryStore/RemoveWord", {
        type,
        word,
        analyzeCategoryId: this.value.relation.params.analyzeCategoryId,
      });
      if (type === "bad") this.onBadWordsPaginationChange();
      else this.onGoodWordsPaginationChange();
    },
    async onWordUpdate(type, word) {
      await this.$store.dispatch("AnalyzeCategoryStore/UpdateWord", {
        type,
        word,
        analyzeCategoryId: this.value.relation.params.analyzeCategoryId,
      });
    },
    async onBadWordsPaginationChange() {
      this.badWords = await this.$store.dispatch(
        "AnalyzeCategoryStore/GetAnalyzeCategoryBadWords",
        {
          analyzeCategoryId: this.value.relation.params.analyzeCategoryId,
          pagination: this.badWords.pagination,
        }
      );
    },
    async onGoodWordsPaginationChange() {
      this.goodWords = await this.$store.dispatch(
        "AnalyzeCategoryStore/GetAnalyzeCategoryGoodWords",
        {
          analyzeCategoryId: this.value.relation.params.analyzeCategoryId,
          pagination: this.goodWords.pagination,
        }
      );
    },
  },
  watch: {
    displayedAnalyzeCategories: {
      async handler(newVal) {
        if (newVal) {
          this.analyzeCategory = this.analyzeCategoryById(
            this.value.relation.params.analyzeCategoryId
          );
        }
      },
      deep: true,
    },
  },
};
</script>