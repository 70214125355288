<template>
  <div class="quantity-input-row">
    <v-tooltip v-if="quantityMeasure" bottom>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="quantity-measure-text"
          >( {{ quantityMeasure }} )</span
        >
      </template>
      <span>{{ quantityMeasureHelpText }}</span>
    </v-tooltip>

    <div
      class="quantity-input-wrapper"
      :class="{
        'help-margin': !!helpText,
      }"
    >
      <div class="quantity-input">
        <v-icon
          :style="{
            visibility: showEdit ? 'hidden' : 'visible',
          }"
          @click="remove"
        >
          mdi-minus
        </v-icon>
        <v-slide-y-transition hide-on-leave leave-absolute>
          <span
            v-if="!showEdit"
            @click="showEdit = !showEdit"
            class="number-input-view"
          >
            {{ pValeFormatter(value || 0) }}
          </span>
          <v-text-field
            class="free-number-input"
            v-else
            hide-details
            :label="`Enter a value`"
            @input="onInput"
            v-model="pValue"
          ></v-text-field>
        </v-slide-y-transition>
        <v-slide-y-transition hide-on-leave leave-absolute>
          <v-icon v-if="!showEdit" @click="add" color="primary">
            mdi-plus
          </v-icon>
          <v-icon v-else @click="showEdit = false" color="success">
            mdi-check
          </v-icon>
        </v-slide-y-transition>
      </div>

      <v-divider
        v-if="!showEdit"
        dark
        :class="{ dark }"
        class="number-input-divider"
        color="white"
      ></v-divider>
    </div>
    <v-tooltip v-if="helpText" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="grey" size="18" dark v-bind="attrs" v-on="on">
          mdi-help
        </v-icon>
      </template>
      <span>{{ helpText }}</span>
    </v-tooltip>
  </div>
</template>


<script>
export default {
  props: {
    value: {},
    step: {
      type: Number,
      default: 1,
    },
    helpText: {
      type: String,
      default: "",
    },
    quantityMeasure: {
      type: String,
      default: "",
    },
    quantityMeasureHelpText: {
      type: String,
      default: "",
    },
    valeFormatter: {
      type: Function,
    },
    dark: {
      type: Boolean,
      default: true,
    },
    max: {
      type: Number,
      default: undefined,
    },
    min: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      pValue: parseInt(this.value) || 0,
      showEdit: false,
    };
  },
  methods: {
    add() {
      this.showEdit = false;
      this.pValue = parseFloat(this.pValue);

      if (this.max && this.max < this.pValue + this.step) return;

      this.pValue = Math.round((this.pValue + this.step) * 100) / 100;
      console.log("this.pValue: ", this.pValue);
      this.$emit("input", this.pValue);
    },
    remove() {
      this.showEdit = false;
      this.pValue = parseFloat(this.pValue);
      if (this.min > this.pValue - this.step) return;

      this.pValue = Math.round((this.pValue - this.step) * 100) / 100;
      this.$emit("input", this.pValue);
    },
    onInput() {
      this.$emit("input", this.pValue);
    },
    pValeFormatter(val) {
      if (this.valeFormatter) return this.valeFormatter(val);
      else return val;
    },
  },
  watch: {
    value(newVal) {
      this.pValue = newVal;
    },
  },
};
</script>


<style lang="scss" scoped>
.quantity-input-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 3px;
  .quantity-measure-text {
    padding-right: 10px;

    font-size: 15px;
    margin-top: 6px;
  }
  .quantity-input-wrapper {
    flex-grow: 1;

    &.help-margin {
      margin-right: 10px;
    }

    .quantity-input {
      padding-left: 4px;
      padding-right: 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;
      .number-input-view {
        margin-left: 5px;
        margin-right: 5px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.375rem;
        cursor: pointer;
      }
    }
    .number-input-divider {
      margin-top: 8px;

      &.dark {
        border-color: white !important;
      }
    }
  }
}
</style>