<template>
  <v-card class="transparent ma-0 pa-2 elevation-0">
    <v-card-title class="lead-title">
      {{ value.name }}
    </v-card-title>
    <v-divider class="ma-4"></v-divider>

    <v-card-text class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <v-col cols="6" class="flex-col">
          <!-- CONFIG -->
          <FormSection
            underline
            right
            class="mt-0"
            :label="'General'"
            :icon="'mdi-pencil'"
            :editable="false"
          ></FormSection>
          <HelpFormInputVue
            dark
            v-model="form.name"
            :rules="nameRules"
            label="Analyze Category Name"
            :helpText="'Provide a name'"
            required
            @input="$emit('onChange')"
          ></HelpFormInputVue>

          <HelpFormInputVue
            dark
            v-model="form.execution_schedule"
            label=" Execution Schedule"
            :helpText="'Please provide an execution schedule in cron format'"
            required
            @input="$emit('onChange')"
          ></HelpFormInputVue>

          <HelpFormInputVue
            dark
            v-model="form.link"
            :rules="linkRules"
            label="Link to use"
            :helpText="'This is a basic link with search parameters that will be used as a basis for search engine'"
            required
            @input="$emit('onChange')"
          ></HelpFormInputVue>
        </v-col>

        <v-col cols="6" class="flex-col">
          <!-- CONFIG -->
          <FormSection
            underline
            right
            class="mt-0"
            :label="'Config'"
            :icon="'mdi-cog'"
            :editable="false"
          ></FormSection>
          <NumberInput
            dark
            v-model="form.config.badThreshold"
            :min="0"
            :step="0.1"
            :max="1"
            :helpText="'The minimum threshold that will be used to filter BAD words similarity'"
            @input="$emit('onChange')"
          ></NumberInput>

          <NumberInput
            dark
            v-model="form.config.goodThreshold"
            :min="0"
            :step="0.1"
            :max="1"
            :helpText="'The minimum threshold that will be used to filter GOOD words similarity'"
            @input="$emit('onChange')"
          ></NumberInput>

          <NumberInput
            dark
            v-model="form.config.pagesLimit"
            :min="0"
            :step="1"
            :max="20"
            :helpText="'The number of pages to verify on execution'"
            @input="$emit('onChange')"
          ></NumberInput>

          <HelpFormInputVue
            dark
            v-model="form.config.stopSelector"
            label="Stop Selector"
            :helpText="'Selector of the element on the page that will stop execution'"
            required
            @input="$emit('onChange')"
          ></HelpFormInputVue>
        </v-col>
      </v-row>

      <!-- GOOD AND BAD WORDS -->
      <v-tabs
        v-model="tab"
        background-color="transparent"
        centered
        icons-and-text
        class="mt-4"
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          Bad Words ({{ badWords.pagination.total }})
          <v-icon color="primary">mdi-block-helper</v-icon>
        </v-tab>

        <v-tab href="#tab-2">
          Good Words ({{ goodWords.pagination.total }})
          <v-icon color="success">mdi-check</v-icon>
        </v-tab>
      </v-tabs>



      <v-tabs-items
        dark
        v-model="tab"
        background-color="transparent"
        class="transparent"
      >
        <v-tab-item :value="'tab-1'">
          <v-row>
            <v-col cols="1" class="pa-0 ma-0 actions-col">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="onBadWordCreate"
                    icon
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>mdi-check</v-icon></v-btn
                  >
                </template>
                <span>Attach new Bad Word</span>
              </v-tooltip>
            </v-col>
            <v-col cols="5" class="words-col">
              <HelpFormInputVue
                dark
                v-model="newBadWord"
                label="New Bad Word"
                :helpText="'Badword you want to attach'"
                required
              ></HelpFormInputVue>
            </v-col>
            <v-col cols="6">
              <NumberInput
                dark
                v-model="newBadWordWeight"
                :min="0"
                :step="1"
                :max="20"
                :helpText="'BadWord Weight'"
              ></NumberInput>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <!-- BAD WORDS LIST -->
          <v-row
            class="word-row"
            v-for="badWord in badWords.items"
            :key="badWord.id"
          >
            <v-col cols="1" class="pa-0 ma-0 actions-col">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="$emit('onWordRemove', 'bad', badWord)"
                    icon
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </template>
                <span>Detach Bad Word</span>
              </v-tooltip>
            </v-col>
            <v-col cols="5" class="words-col">
              <span class="word-name">{{ badWord.word.word }}</span>
            </v-col>
            <v-col cols="6">
              <NumberInput
                dark
                @input="$emit('onWordUpdate', 'bad', badWord)"
                v-model="badWord.weight"
                :min="0"
                :step="0.05"
                :max="20"
                :helpText="'BadWord Weight'"
              ></NumberInput>
            </v-col>
          </v-row>
          <v-pagination
            class="mt-4"
            @input="$emit('onBadWordsPaginationChange')"
            v-if="badWords.pagination.page"
            v-model="badWords.pagination.page"
            :length="badWordsPaginationLength"
            :total-visible="4"
          ></v-pagination>
        </v-tab-item>
        <!-- GOOD WORDS -->
        <v-tab-item :value="'tab-2'">
          <v-row>
            <v-col cols="1" class="pa-0 ma-0 actions-col">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="
                      $emit('onWordCreate', 'good', {
                        word: newGoodWord,
                        weight: newGoodWordWeight,
                      })
                    "
                    icon
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>mdi-check</v-icon></v-btn
                  >
                </template>
                <span>Attach new Good Word</span>
              </v-tooltip>
            </v-col>
            <v-col cols="5" class="words-col">
              <HelpFormInputVue
                dark
                v-model="newGoodWord"
                label="New Good Word"
                :helpText="'Goodword you want to attach'"
                required
              ></HelpFormInputVue>
            </v-col>
            <v-col cols="6">
              <NumberInput
                dark
                v-model="newGoodWordWeight"
                :min="0"
                :step="1"
                :max="20"
                :helpText="'BadWord Weight'"
              ></NumberInput>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <!-- GOOD WORDS LIST -->
          <v-row
            class="word-row"
            v-for="goodWord in goodWords.items"
            :key="goodWord.id"
          >
            <v-col cols="1" class="pa-0 ma-0 actions-col">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="$emit('onWordRemove', 'good', goodWord)"
                    icon
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </template>
                <span>Detach Good Word</span>
              </v-tooltip>
            </v-col>
            <v-col cols="5" class="words-col">
              <span class="word-name">{{ goodWord.word.word }}</span>
            </v-col>
            <v-col cols="6">
              <NumberInput
                @input="$emit('onWordUpdate', 'good', goodWord)"
                dark
                v-model="goodWord.weight"
                :min="0"
                :step="0.05"
                :max="20"
                :helpText="'BadWord Weight'"
              ></NumberInput>
            </v-col>
          </v-row>
          <v-pagination
            class="mt-4"
            @input="$emit('onGoodWordsPaginationChange')"
            v-if="goodWords.pagination.page"
            v-model="goodWords.pagination.page"
            :length="goodWordsPaginationLength"
            :total-visible="4"
          ></v-pagination>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>
      
      
  <script>
import { FormatterHelper } from "../../../../helpers/formatter.helper";
// import FormSection from "../../atoms/common/FormSection.vue";
import NumberInput from "../../../atoms/common/NumberInput.vue";
import FormSection from "../../../atoms/common/FormSection.vue";
import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },

    badWords: {
      default: () => ({
        items: [],
        pagination: {},
      }),
    },

    goodWords: {
      default: () => ({
        items: [],
        pagination: {},
      }),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FormSection,
    NumberInput,
    HelpFormInputVue,
  },
  data() {
    let form = this.value;

    console.log("VALUE: ", JSON.stringify(this.value.config));

    if (typeof this.value.config === "string") {
      let config;
      try {
        console.log("this.value.config: ", this.value.config);

        config = JSON.parse(this.value.config);
      } catch (error) {
        config = {};
      }

      form.config = config;
    }

    return {
      tagIndex: null,
      tab: "tab-1",
      form,
      durationFormatter: FormatterHelper.timeDuration,

      newBadWord: "",
      newBadWordWeight: 0.1,

      newGoodWord: "",
      newGoodWordWeight: 0.1,

      // ======rules
      nameRules: [(v) => !!v || "Name is required"],
      descriptionRules: [(v) => !!v || "Description is required"],
      linkRules: [
        (v) => !!v || "Link is required",
        (v) => /(https?:\/\/[^\s]+)/.test(v) || "Link should be a proper link",
      ],
    };
  },
  computed: {
    badWordsPaginationLength: function () {
      return Math.round(
        this.badWords.pagination.total / this.badWords.pagination.pageSize
      );
    },
    goodWordsPaginationLength: function () {
      return Math.round(
        this.goodWords.pagination.total / this.goodWords.pagination.pageSize
      );
    },
  },
  async created() {},
  methods: {
    onBadWordCreate() {
      this.$emit("onWordCreate", "bad", {
        word: this.newBadWord,
        weight: this.newBadWordWeight,
      });

      this.newBadWord = "";
      this.newBadWordWeight = 0.5;
    },
    onGoodWordCreate() {
      this.$emit("onWordCreate", "good", {
        word: this.newGoodWord,
        weight: this.newGoodWordWeight,
      });

      this.newGoodWord = "";
      this.newGoodWordWeight = 0.5;
    },
  },
  watch: {
    value: {
      handler(newVal) {
        if (typeof newVal.config === "string") {
          let config;
          try {
            config = JSON.parse(newVal.config);
          } catch (error) {
            config = {};
          }
          newVal.config = config;
          this.form = newVal;
        } else {
          this.form = newVal;
        }
      },
      deep: true,
    },
  },
};
</script>


<style lang="scss" scoped>
.lead-title {
  text-transform: uppercase;
  display: inline-block;
}

.title-link {
  text-decoration: none;
  color: white;
  transition: color 0.12s ease-in-out;

  &:hover {
    color: brown;
  }
}

.actions-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.word-row {
  transition: all 0.1s;

  &:hover {
    background: rgba($color: #ffffff, $alpha: 0.1);
  }
}
.words-col {
  display: flex;
  align-items: center;
}

.word-name {
  font-size: 18px;
  line-height: 20px;
}
</style>